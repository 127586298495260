import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthProviderService } from '../-services-/auth-framework/auth-provider.service';
import { Role } from '../-services-/auth-framework/api-constants/roles/role';
import { IApplication } from '../-services-/auth-framework/api-constants/applications/application';
import { eSupportRole } from '../-services-/auth-framework/api-constants/roles/role-instances';

export interface CustomRoute extends Route {
  roles?: Role[];
  applications?: IApplication[];
}

@Injectable({
  providedIn: 'root'
})
export class CanMatchRoute {
  constructor(private authService: AuthProviderService) {}

  canMatch(route: CustomRoute, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const isEsupportUser = this.authService.verifyUserRole(eSupportRole);
    const isAllowedRole = route.roles?.length ? route.roles.some((item) => this.authService.verifyUserRole(item)) : true;
    const isAllowedApp = route.applications?.length ? route.applications.some((item) => this.authService.verifyUserApplication(item)) : true;
    return isAllowedRole && (isEsupportUser || isAllowedApp);
  }
}
